(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/utils/get-optimal-width/assets/javascripts/get-optimal-width.js') >= 0) return;  svs.modules.push('/components/utils/get-optimal-width/assets/javascripts/get-optimal-width.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const isServer = typeof exports === 'object';
let logger;
if (isServer) {
  const trinidad = require('trinidad-core').core;
  logger = trinidad.logger('utils:get-optimal-width');
} else {
  logger = svs.core.log.getLogger('utils:get-optimal-width');
}

const getOptimalWidth = (availableWidths, requestedWidth) => {
  if (!(availableWidths !== null && availableWidths !== void 0 && availableWidths.length)) {
    logger.warn('availableWidths must be a non-empty array.');
    return requestedWidth;
  }
  if (typeof requestedWidth !== 'number') {
    logger.warn('requestedWidth must be a number.');
    return requestedWidth;
  }
  availableWidths.sort((a, b) => Math.abs(a - requestedWidth) - Math.abs(b - requestedWidth));
  const optimalWidth = availableWidths[0];
  return optimalWidth;
};

svs.utils = _objectSpread(_objectSpread({}, svs.utils), {}, {
  getOptimalWidth
}) || {
  getOptimalWidth
};

if (svs.isServer) {
  module.exports = svs.utils.getOptimalWidth;
}

 })(window);